<template>
  <div id="add-new-product">
    <div class="vx-row">
      <div class="vx-col lg:w-2/3 w-full">
        <vx-card>
          <form>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full">
                <vs-input v-validate="{ required: true, regex: /^[a-zA-Z0-9-&_() ]+$/}" label="Product Name *" placeholder="Product Name" name="name" v-model="data.name" class="mt-5 w-full" />
                <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
              </div>
              <div class="vx-col md:w-1/2 w-full">
                <vs-select autocomplete  v-validate="'required'" v-model="data.category_id" name="category" placeholder="--Choose Category--" label="Product Category *" class="select-large mt-5 w-full">
                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categoryList" />
                </vs-select>
                <span class="text-danger text-sm" v-show="errors.has('category')">{{ errors.first('category') }}</span>
              </div>
              <div class="vx-col md:w-1/2 w-full">
                <vs-input v-validate="{ required: true, regex: /^[a-z0-9-_]+$/}" label="Slug *" name="slug" v-model="data.slug" class="mt-5 w-full" placeholder="Enter URL name" />
                <span class="text-danger text-sm" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
              </div>
              <div class="vx-col md:w-1/2 w-full">
                <vs-input icon-pack="feather" icon="icon-dollar-sign" v-validate="{ required: true, regex: /^[0-9]+$/}" type="number" label="Price (dollar) *"
                 name="price" v-model="data.price" class="mt-5 w-full" placeholder="Price" />
                <span class="text-danger text-sm" v-show="errors.has('price')">{{ errors.first('price') }}</span>
              </div>
              <div class="vx-col w-full mt-5">
                <vs-checkbox name="is_featured" class="inline-flex" v-model="data.is_featured">Make Featured Product</vs-checkbox>
              </div>
              <div class="vx-col mt-5 w-full">
                <h4 class="mb-2">Product Image</h4>
                <vue-dropzone id="proUploadDrop" ref="proImageUploadDrop"
                  :use-custom-slot="true" :maxFiles="1" :maxFilesize="200"
                  acceptedFiles="image/*,.webp">
                  <div class="needsclick m-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                    <h4 class="mb-2">Upload File or Drop file</h4>
                    <p> Dimension should be <code>500px</code> x <code>500px</code></p>
                  </div>
                </vue-dropzone>
              </div>
              </div>
              <div class="flex flex-wrap justify-end items-center mt-5 pt-5">
                <vs-button type="filled" @click.prevent="submitForm" class="mr-6">Add Product</vs-button>
                <vs-button color="danger" type="border" @click="data = {}; $router.push('/products').catch(() => {})">Cancel</vs-button>
              </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VueDropzone from "@/components/VueDropzone.vue";

export default{
  components:{
    VueDropzone
  },
  data() {
    return {
      data: {
        name: "",
        category_id: "",
        slug: "",
        is_featured: false,
        price: "",
      },
    }
  },
  watch: {
    'data.name': function (name){
      this.data.slug = name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
    },
  },
  computed: {
    categoryList() {
       return this.$store.state.categoryList.categories
    },
  },
  methods: {
    submitForm() {
      if(!this.collectFiles().length){ this.alertError("Product image is required"); return;}
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({color: "#444", type:'sound'})
          const formData = new FormData()
          formData.append('image_file', this.collectFiles()[0])
          formData.append('data', JSON.stringify(this.data))
          this.$http.post("/products/create", formData)
          .then((response)=>{
            this.$vs.loading.close()
             if(response.data.success){
              this.resetForm()
              this.$store.commit('productList/ADD_PRODUCT', response.data.data)
            }
          })
        }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.proImageUploadDrop){
        arrafile = [...this.$refs.proImageUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetForm() {
      this.data = {
        name: "",
        category_id: "",
        slug: "",
        is_featured: false,
        is_offer: false,
        price: "",
        brief_detail: ""
      }
      this.$validator.reset()
       this.$refs.proImageUploadDrop.removeAllFiles();
      this.popupUploadBanner = false
    },
  },
  created(){
    if(!this.categoryList.length){
      this.$store.dispatch("productList/fetchProducts")
    }
  }
}
</script>
